import React from 'react';

// 公共Context，用于获取全局数据，如：语言、多语言数据等
export const CommonContext = React.createContext({
  userGold: 0,
  bannerList: [],
  listCategory: [],
  productList: [],
  exchangeList: [],
  sortId: null,
  setSortId: () => {},
  setPoints: () => {},
  productPageNumber: 0,
  setProductPageNumber: () => {},
  productTotalPage: 0,
});
