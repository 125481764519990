import React from 'react';
import { inject, observer } from 'mobx-react';

import dynamic from 'next/dynamic';
import { getToken } from '@/utils';
import { CommonContext } from './context';
import Api from './api';

const Desktop = dynamic(() => import('./desktop'), { ssr: true });
const Mobile = dynamic(() => import('./mobile'), { ssr: true });

const Index = inject(
  'area',
  'app'
)(
  observer((props) => {
    const {
      country: { code, language },
      LANG,
    } = props.area;
    const { isMobile } = props.app;
    // 轮播图列表（服务器获取）
    const bannerList = props.bannerList;
    // 兑换排名列表
    const [exchangeList, setExchangeList] = React.useState([]);
    // 用户头像列表
    const [userAvatarList, setUserAvatarList] = React.useState([]);
    // 当前分类产品列表
    const [productList, setProductList] = React.useState([]);
    // 分类列表（给予2个默认值）
    const [productSort, setProductSort] = React.useState(() => {
      const token = getToken();
      const list = [
        {
          id: 'hot',
          productCategoryInfo: {
            name: LANG['index.hot'],
          },
        },
      ];
      if (token) {
        list.push({
          id: 'exchange',
          productCategoryInfo: {
            name: LANG['index.can_exchange'],
          },
        });
      }
      return list;
    });

    // 产品loading
    const [productLoading, setProductLoading] = React.useState(true);
    /**
     * 分类ID
     * -  热门 -> hot
     * -  我能兑换 -> exchange
     * -  其他 -> 分类ID
     */
    const [sortId, setSortId] = React.useState('hot');
    // 积分筛选
    const [points, setPoints] = React.useState();
    // 产品列表 - 分页
    const [productPageNumber, setProductPageNumber] = React.useState(1);
    const [productTotalPage, setProductTotalPage] = React.useState(1);
    // 用户积分
    const [userGold, setUserGold] = React.useState();

    // 获取榜单数据
    const getEveryoneExchange = React.useCallback(() => {
      Api.getEveryoneExchange({
        CountryCode: code,
        X_Language: language,
        root: props.app,
      })
        .then((res) => {
          let list = [];
          res.data.list?.forEach((item) => {
            let productInfo, productInfoEn;
            const { productInfoList, ...values } = item;
            productInfoList.forEach((item2) => {
              if (item2.language.toLowerCase() === language) {
                productInfo = item2;
              }
              if (item2.language.toLowerCase() === 'en_us') {
                productInfoEn = item2;
              }
            });

            if (!productInfo && !productInfoEn) return;

            list.push({
              ...values,
              productInfo: productInfo ?? productInfoEn,
            });
          });
          setExchangeList(list);
        })
        .catch(() => {
          console.log(`【获取榜单列表失败】`);
        });
    }, []);
    // 获取用户积分
    const getTotalPoints = React.useCallback(() => {
      const token = getToken();
      if (!token) return;
      Api.getTotalPoints({ root: props.app, token })
        .then((res) => {
          setUserGold(res.data.total_points);
        })
        .catch(() => {
          console.log('【获取用户积分失败】');
        });
    }, []);
    // 获取用户头像列表
    const getUserAvatarList = React.useCallback(() => {
      Api.getUserAvatarList({ root: props.app, CountryCode: code, X_Language: language })
        .then((res) => {
          setUserAvatarList(res.data.listEveryoneCashing);
        })
        .catch(() => {
          console.log(`【获取榜单列表失败】`);
        });
    }, []);
    // 获取商品分类
    const getProductSort = React.useCallback(() => {
      Api.getProductSort({
        CountryCode: code,
        X_Language: language,
        root: props.app,
      }).then((res) => {
        const { productCategoryList = [] } = res.data;
        let list = [];
        productCategoryList.forEach((item) => {
          let productCategoryInfo, productCategoryInfoEn;
          const { productCategoryInfoList, ...values } = item;
          productCategoryInfoList.forEach((item2) => {
            if (item2.language.toLowerCase() === language) {
              productCategoryInfo = item2;
            }
            if (item2.language.toLowerCase() === 'en_us') {
              productCategoryInfoEn = item2;
            }
          });
          if (!productCategoryInfo && !productCategoryInfoEn) return;
          list.push({
            ...values,
            productCategoryInfo: productCategoryInfo ?? productCategoryInfoEn,
          });
        });
        setProductSort((state) => [...state, ...list]);
        // setSortId(list[0].id);
      });
    }, []);
    // 获取普通商品列表
    const getProductList = React.useCallback((data) => {
      return new Promise((resolve, reject) => {
        Api.getProductList(data)
          .then((res) => {
            let list = [];
            res.data.list?.forEach((item) => {
              const { productInfoList, ...values } = item;

              let productInfo, productInfoEn;

              productInfoList.forEach((item2) => {
                if (item2.language.toLowerCase() === language) {
                  productInfo = item2;
                }
                if (item2.language.toLowerCase() === 'en_us') {
                  productInfoEn = item2;
                }
              });

              if (!productInfo && !productInfoEn) return;

              list.push({
                ...values,
                productInfo: productInfo ?? productInfoEn,
              });
            });
            resolve(list);
            setProductTotalPage(res.data.totalPage);
          })
          .catch((err) => {
            reject(`【获取商品列表失败】 - productCategoryId: ${sortId}, Error: ${err}`);
          });
      });
    }, []);
    // 获取热门商品列表
    const getHotProductList = React.useCallback((data) => {
      return new Promise((resolve, reject) => {
        Api.getHotProductList(data)
          .then((res) => {
            let list = [];
            res.data.popularProductList?.forEach((item) => {
              const { productInfoList, ...values } = item;
              let productInfo, productInfoEn;
              productInfoList.forEach((item2) => {
                if (item2.language.toLowerCase() === language) {
                  productInfo = item2;
                }
                if (item2.language.toLowerCase() === 'en_us') {
                  productInfoEn = item2;
                }
              });
              if (!productInfo && !productInfoEn) return;
              list.push({
                ...values,
                productInfo: productInfo ?? productInfoEn,
              });
            });
            resolve(list);
            setProductTotalPage(1);
          })
          .catch((err) => {
            reject(new Error(`【获取热门商品列表失败】`));
          });
      });
    }, []);
    // 获取我们兑换的商品列表
    const getListCanExchange = React.useCallback((data) => {
      return new Promise((resolve, reject) => {
        Api.getListCanExchange(data)
          .then((res) => {
            let list = [];
            res.data.list?.forEach((item) => {
              const { productInfoList, ...values } = item;
              let productInfo;
              let productInfoEn;
              if (productInfoList) {
                productInfoList.forEach((item2) => {
                  if (item2.language.toLowerCase() === language) {
                    productInfo = item2;
                  }
                  if (item2.language.toLowerCase() === 'en_us') {
                    productInfoEn = item2;
                  }
                });
              }

              if (!productInfo && !productInfoEn) return;
              list.push({
                ...values,
                productInfo: productInfo ?? productInfoEn,
              });
            });
            resolve(list);
            setProductTotalPage(res.data.totalPage);
          })
          .catch(() => {
            reject(new Error(`【获取我能兑换商品列表失败】`));
          });
      });
    }, []);

    // 根据分类、积分获取商品列表
    React.useEffect(() => {
      setProductPageNumber(1);
      const data = {
        CountryCode: code,
        X_Language: language,
        pageNumber: 1,
        pageSize: isMobile ? 10 : 12,
        root: props.app,
        startPoints: points && points[0],
        endPoints: points && points[1],
      };
      setProductLoading(true);
      if (sortId === 'hot') {
        getHotProductList(data)
          .then((res) => {
            setProductList(res);
          })
          .catch(() => {
            setProductList([]);
          })
          .finally(() => {
            setProductLoading(false);
          });
      } else if (sortId === 'exchange') {
        getListCanExchange({
          ...data,
          instaToken: getToken(),
        })
          .then((res) => {
            setProductList(res);
          })
          .catch((err) => {
            setProductList([]);
          })
          .finally(() => {
            setProductLoading(false);
          });
      } else {
        getProductList({
          ...data,
          productCategoryId: sortId,
        })
          .then((res) => {
            setProductList(res);
          })
          .catch(() => {
            setProductList([]);
          })
          .finally(() => {
            setProductLoading(false);
          });
      }
    }, [sortId, points]);

    /**
     * 初始化数据
     * PC端：
     *  - 获取兑换排名
     *  - 获取产品分类列表
     *  - 获取头像图片列表
     * Mob端：
     * - 获取总积分
     * - 获取产品分类列表
     * - 获取兑换排名
     */
    React.useEffect(() => {
      getEveryoneExchange();
      getProductSort();
      if (isMobile) {
        getTotalPoints();
      } else {
        getUserAvatarList();
      }
    }, []);
    return (
      <CommonContext.Provider
        value={{
          listCategory: productSort,
          userAvatarList,
          userGold,
          bannerList,
          productList,
          exchangeList,
          sortId,
          setSortId,
          setPoints,
          productPageNumber,
          setProductPageNumber: (pageNumber) => {
            // 分页处理
            setProductPageNumber(pageNumber);

            const data = {
              CountryCode: code,
              X_Language: language,
              productCategoryId: sortId,
              pageNumber,
              pageSize: isMobile ? 10 : 12,
              root: props.app,
              startPoints: points && points[0],
              endPoints: points && points[1],
            };

            if (sortId === 'exchange') {
              getListCanExchange({
                ...data,
                instaToken: getToken(),
              }).then((res) => {
                setProductList((state) => [...state, ...res]);
              });
            } else {
              getProductList(data).then((res) => {
                setProductList((state) => [...state, ...res]);
              });
            }
          },
          productTotalPage,
          productLoading,
        }}
      >
        {isMobile ? <Mobile /> : <Desktop />}
      </CommonContext.Provider>
    );
  })
);

export async function getServerSideProps({ res }) {
  return {
    props: {
      bannerList: res.pageData?.bannerList ?? [],
    },
  };
}

export default Index;
